@charset "UTF-8";

/* ==========================================================================
   Imports
   ========================================================================== */

@import "normalize";
@import "mixins";
@import "variables";
@import "grid";
@import "type";
@import "styles";

// Remove the definition below for a pixel-based layout
$total-width: 100%;
