/* ==========================================================================
   VARIABLES
   ========================================================================== */

/* Colors
   ========================================================================== */

$black:  #3b3b3b;
$white: #f9f9f9;

$grey-dark:   #222;
$grey-medium: #999;
$grey-light:  #d9d9d9;

$hilite: #fffbd7;

$link: #8AB9D1;
$accent: #24536B;


/* Typography
   ========================================================================== */
$sans: "Source Sans Pro", "Open Sans", "Helvetica Neue", "Arial", sans-serif;
$serif: "Calendas Plus","Crimson Text", "Hoefler Text", "Georgia", serif;

   
   
/* Type Scale
   ========================================================================== */
/** * 12 & 16 as roots then powers of two. */
$fs1:64px;
$fs2:48px;
$fs3:32px;
$fs4:24px;
$fsp:22px;
$fs5:16px;
$fs6:12px;

// Line Height
$lh: 33px;
$lhsmall: 24px;

// Sizing 
$footerHeight: 320px;

// Griddit
/* Break Points
   ========================================================================== */
   
/* Mobile */
$breakpoint-1-max-width:480px;

/* Tablet */
$breakpoint-2-max-width:800px;
$breakpoint-3-max-width:1200px;

/* Style Tidbits
   ========================================================================== */
$borderR: 4px;

